/**
 * Used for javascript that needs to be executed globally
 */

import Api from "./lib/api";

// This code is used to initialize Savvycal booking link
console.log("initializing globals");

window.mxu = { initializeSavvyCalIn };

function initializeSavvyCalIn(selector) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const email = params.get("email");
  const name = params.get("name");

  SavvyCal("init");

  SavvyCal("inline", {
    link: "mxu-team/enroll-in-mxu-academy",
    selector,
    email,
    displayName: name,
    theme: "dark",
    view: "month",
    hideAvatar: true,
    hideBanner: true,
    metadata: { from: "app_enroll_flow" },
  });

  window.addEventListener("savvycal.scheduled", (event) => {
    const { id, email, displayName, startAt, endAt, url, timeZone } =
      event.detail;

    const params = new URLSearchParams({
      email,
      name: displayName,
      url,
      startAt,
      endAt,
      timeZone,
    });

    const newHref = `/marketing/enrolls/success?${params.toString()}`;

    Api.Interactions.createBookingInteraction({
      email,
      name,
      eventId: id,
      eventUrl: url,
    });

    Turbo.visit(newHref, { action: "advance" });
  });
}
