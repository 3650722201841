import * as PlaylistsApi from "./playlists";
import * as CheckOutsApi from "./checkouts";
import * as NotificationsApi from "./notifications";
import InteractionsApi from "./interactions";
import * as Util from "../util";

function baseUrl() {
  if (Util.isProduction()) {
    return "https://app.getmxu.com";
  } else {
    return "http://app.lvh.me";
  }
}

export default {
  Checkouts: CheckOutsApi,
  Playlists: PlaylistsApi,
  Notifications: NotificationsApi,
  Interactions: InteractionsApi,
  baseUrl,
};
