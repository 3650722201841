import Rails from "@rails/ujs";
import Api from "../api";

function createButtonInteraction({
  source,
  name,
  location,
  detail,
  featureName,
}) {
  const payload = {
    interaction: {
      type: "Interaction::Button",
      data: JSON.stringify({
        source,
        name,
        location,
        detail,
        feature_name: featureName,
      }),
    },
  };

  doFetch(payload);
}

function createBookingInteraction({ email, name, eventId, eventUrl }) {
  const payload = {
    interaction: {
      type: "Interaction::Booking",
      data: JSON.stringify({
        email,
        name,
        event_id: eventId,
        event_url: eventUrl,
      }),
    },
  };

  doFetch(payload);
}

function doFetch(payload) {
  let body = JSON.stringify(payload);

  fetch(`${Api.baseUrl()}/interactions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    body,
  })
    .then((res) => {
      if (res.ok) return;

      console.error(
        `attempted to submit a interaction ${body} but the server responded ${res.status}`
      );
    })
    .catch((error) => {
      console.error(
        `attempted to submit a interaction ${body} error: ${error.message}`
      );
    });
}

export default {
  createButtonInteraction,
  createBookingInteraction,
};
